<template>
  <div class="data-room">
    <div class="data-room-top">
      <div class="tea-search">
        <el-form ref="form" :inline="true" class="demo-form-inline">
          <!-- 班级状态 -->
          <el-form-item label="班级状态:" v-show="class_tab_index=='second'">
            <el-select v-model="search_data.status" placeholder="请选择" @change="handelSearch">
              <el-option :value="null" label="全部" :key="null"></el-option>
              <el-option :value="1" label="有效" :key="1"></el-option>
              <el-option :value="0" label="无效" :key="0"> </el-option>
              <el-option :value="2" label="未生效" :key="2"> </el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item>
            <div class="search-text" style="width:300px">
              <el-input placeholder="请输入班级名称或关键字查询" v-model="search_data.bjmc" class="input-with-select">
              </el-input>
            </div>
          </el-form-item>
          <!-- 查询按钮 -->
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" class="fl tea-btn" @click="handelSearch">查 询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <!-- tab切换 -->
      <el-tabs type="border-card" v-model="class_tab_index" @tab-click='tabClick()'>
        <!-- <el-tab-pane label="常规班级" name="first" disabled></el-tab-pane> -->
        <el-tab-pane label="临时班级（已安排集训）" name="second"></el-tab-pane>
      </el-tabs>
      <!-- tableData -->
      <el-table stripe border :data="tableData" height="calc(100% - 90px)">
        <el-table-column label="序号" type="index" align="center" :index="indexMethod" width="80" />
        <el-table-column prop="bjmc" label="班级名称" align="center" />
        <!-- 临时班特殊 -->
        <el-table-column prop="bjmc" label="班级有效时间" align="center" width="200">
          <template v-slot="scope">
            <span>{{new Date(scope.row.yxqq).Format("yyyy-MM-dd")}}</span>
            至
            <span>{{new Date(scope.row.yxqz).Format("yyyy-MM-dd")}}</span>
          </template>
        </el-table-column>
        <!--常规班特殊 -->
        <el-table-column prop="bjbm" label="班级编号" align="center" v-if="class_tab_index == 'first'" />
        <el-table-column prop="personCount" label="班级人数" align="center" width="120">
          <template #header>
            <div class="clearfix">
              <span class="header-title">班级人数</span>
              <el-tooltip class="item" effect="dark" content="班级人数 = 学生人数+教师人数" placement="top-end">
                <i class="el-icon-info" style="color:#409EFF;margin-left:10px"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="bzrxm" label="班主任" align="center" v-if="class_tab_index == 'first'" />
        <el-table-column prop="bzrsjh" label="发布总任务数" align="center" v-if="class_tab_index == 'first'" />
        <el-table-column prop="bzrsjh" label="完成任务总数" align="center" v-if="class_tab_index == 'first'" />
        <el-table-column prop="bzrsjh" label="任务完成率" align="center" v-if="class_tab_index == 'first'" />
        <!-- 临时班 平均学习进度 -->
        <el-table-column prop="jxmc" label="关联集训" align="center" v-if="class_tab_index == 'second'" />
        <el-table-column prop="bjmc" label="集训有效时间" align="center" width="200">
          <template v-slot="scope">
            <span>{{ scope.row.jxYxqq ?  new Date(scope.row.jxYxqq).Format("yyyy-MM-dd") : ''}}</span>
            至
            <span>{{ scope.row.jxYxqz ?  new Date(scope.row.jxYxqz).Format("yyyy-MM-dd") : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="jxStatus" label="集训状态" align="center" v-if="class_tab_index == 'second'" width="100">
          <template v-slot="scope">
            <el-tag v-show="scope.row.jxStatus=='未开始'">未开始</el-tag>
            <el-tag type="success" v-show="scope.row.jxStatus=='进行中'">进行中</el-tag>
            <el-tag type="warning" v-show="scope.row.jxStatus=='已结束'">已结束</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100px">
          <template v-slot="scope">
            <el-button type="text" @click="lookClassGrade(scope.row)" :disabled="scope.row.jxStatus=='未开始'">查看成绩</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="tea-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pages.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getClassListApi,
  classTempGradeListApi,//临时班级成绩列表
} from '@/api/teacher'

export default {
  name: 'data-room',
  components: {
  },
  data() {
    return {
      yhid: this.$store.state.userInfo.yhid,
      zhid: this.$store.state.userInfo.zhid,
      class_tab_index: 'second',
      tableData: [],
      search_data: {
        yhid: this.$store.state.userInfo.yhid,
        zhid: this.$store.state.userInfo.zhid,
        bjmc: null,
        status: 1,
      },
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      class_list: [],
    }
  },
  mounted() {
    this.getClassList()
  },
  methods: {
    // 切换常规班级临时班级
    tabClick() {
      this.tableData = []
      this.search_data.mc = null
      this.handelSearch()
    },
    // 查询班级班级成绩
    getClassList() {
      const data = this.search_data;
      data.currentPage = this.pages.currentPage;
      data.pageSize = this.pages.pageSize;
      let api = getClassListApi;//常规班成绩还没有接口
      if (this.class_tab_index == 'second') {
        api = classTempGradeListApi;
        data.ywjx = 1;
        data.yycdid = this.$store.state.yycdid
      }
      api(data).then(res => {
        if (res.result) {
          this.pages.total = res.result.total
          this.tableData = res.result.list;
        }
      })
    },
    indexMethod(index) {
      return (this.pages.currentPage - 1) * this.pages.pageSize + index + 1;
    },
    // ------------------------------------------------------分页器
    handleSizeChange(val) {
      this.pages.currentPage = 1;
      this.pages.pageSize = val;
      this.getClassList()
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getClassList()
    },
    // 点击查询
    handelSearch() {
      // 点击查询，页数，每页显示个数，初始化
      this.pages.currentPage = 1;
      this.pages.pageSize = 10
      this.getClassList()
    },
    // ---------------------------------------------进入班级
    // 查看班级集训成绩
    lookClassGrade(result) {
      let lx
      if (this.class_tab_index == 'first') {
        // 常规班
        lx = 1
      } else if (this.class_tab_index == 'second') {
        // 临时班
        lx = 2
      }
      const query = {
        bjid: result.bjid,
        bjmc: result.bjmc,
        yxqq: result.jxYxqq,
        yxqz: result.jxYxqz,
        jxid: result.jxid,
        jxStatus: result.jxStatus,
        lx,
      }
      this.$router.push({ path: '/dataCenter/classGrade', query: query })
      sessionStorage.setItem('router_class_name', result.bjmc)
    },
    splitScreen(result) { // 分屏展示
      const time = {
        yxqq: new Date(result.jxYxqq).Format("yyyy-MM-dd"),
        yxqz: new Date(result.jxYxqz).Format("yyyy-MM-dd"),
        now_select: this.$utils.getNowTime(0, 0, 0,)
      }
      let now = new Date(this.$utils.getNowTime(0, 0, 0,)).getTime()
      let end = new Date(time.yxqz).getTime();
      if (end < now) {
        time.now_select = time.yxqz
      }
      // 1全员排名
      window.open('#/screen/finishStat?bjid=' + result.bjid + '&bjmc=' + result.bjmc + '&zhid=' + this.zhid + '&yhid=' + this.yhid + '&time=' + JSON.stringify(time), 'newwindows' + 1, 'height=800, width=1280, top=100, left=100,toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no')
      // 2小组排名
      window.open('#/screen/groupRank?bjid=' + result.bjid + '&bjmc=' + result.bjmc + '&bjrs=' + result.bjrs + '&zhid=' + this.zhid + '&jxid=' + result.jxid + '&yhid=' + this.yhid + '&time=' + JSON.stringify(time), 'newwindows' + 2, 'height=800, width=1280, top=100, left=100,toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no')
      // 3学员进步排名
      window.open('#/screen/stuAdvanceRank?bjid=' + result.bjid + '&bjmc=' + result.bjmc + '&zhid=' + this.zhid + '&yhid=' + this.yhid + '&time=' + JSON.stringify(time), 'newwindows' + 3, 'height=800, width=1280, top=100, left=100,toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no')
      // 4错题排名
      window.open('#/screen/stuErrorStat?bjid=' + result.bjid + '&bjmc=' + result.bjmc + '&zhid=' + this.zhid + '&yhid=' + this.yhid + '&time=' + JSON.stringify(time), 'newwindows' + 4, 'height=800, width=1280, top=100, left=100,toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no')

    },
  }
}
</script>
<style lang="scss" scoped>
.data-room {
  height: 100%;
  .table {
    height: calc(100% - 100px);
    .btns {
      display: flex;
    }
    .tea-pagination {
      margin-top: 15px;
    }
    .el-tabs--border-card {
      border: 1px solid #ebeef5;
      box-shadow: none;
      border-bottom: none;
      /deep/.el-tabs__header {
        border-bottom: none !important;
      }
    }
  }
}
.data-room-top {
  padding-top: 10px;
}
</style>
<style lang="scss">
.data-room {
  // .el-table__body-wrapper {
  //   height: 100% !important;
  // }
}
</style>



